const bootstrapItems = {
  normal: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'],

  outline: [
    'outline-primary',
    'outline-secondary',
    'outline-success',
    'outline-danger',
    'outline-warning',
    'outline-info',
    'outline-light',
    'outline-dark',
  ],
};

const arrayItems = [
  'bg',
  'bg2',

  'outline-bg',
  'outline-bg2',

  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',

  'link',
  'link btn-bg',

  'outline-primary',
  'outline-secondary',
  'outline-success',
  'outline-danger',
  'outline-warning',
  'outline-info',
  'outline-light',
  'outline-dark',
];

export { arrayItems, bootstrapItems };
