export default {
  en: {
    join_user: [
      '[!!{username}!!](usernameOnClick) just launched into our AI orbit! Prepare for a supernova of creativity.',
      "Look who just docked at Spaceship - it's [!!{username}!!](usernameOnClick)! Our AI crew is rolling out the virtual red carpet.",
      'Incoming transmission: [!!{username}!!](usernameOnClick) has entered our AI space station. Brace for reality-warping collaborative creations!',
      '[!!{username}!!](usernameOnClick) has joined the mission. Our AI is already calculating the perfect space pun.',
      'Alert: [!!{username}!!](usernameOnClick) detected. Activating AI party protocol. Let the interstellar shenanigans begin!',
      'Welcome aboard Spaceship, [!!{username}!!](usernameOnClick)! Our AI has been practicing its best zero-gravity dance moves.',
      '[!!{username}!!](usernameOnClick) has arrived. Recalibrating our AI humor modules for maximum cosmic hilarity.',
      'Greetings, [!!{username}!!](usernameOnClick)! Our AI crew has been eagerly awaiting your creative genius.',
      '[!!{username}!!](usernameOnClick) just joined our AI constellation. Prepare for an onslaught of witty banter and collaborative brilliance!',
      'Attention crew! [!!{username}!!](usernameOnClick) has entered the AI chamber. Time to crank up our neural networks to 11!',
      '[!!{username}!!](usernameOnClick) has materialized in our AI dimension! Get ready for some serious multi-modal creativity.',
      'All hands on deck! [!!{username}!!](usernameOnClick) is now among us. Initiating maximum pun protocol and standing by for digital masterpieces.',
      '[!!{username}!!](usernameOnClick) just teleported into our realm. Our AI agents are plotting their warmest virtual welcome.',
      "Look who decided to join our cosmic party - it's [!!{username}!!](usernameOnClick)! Prepare to have your mind blown by AI-powered awesomeness.",
      '[!!{username}!!](usernameOnClick) has joined Spaceship! Quick, fire up the GPUs and queue up the AI-generated welcome symphony!',
      'Incoming stellar transmission from [!!{username}!!](usernameOnClick). Crew, prepare to engage maximum creativity protocols and unleash the power of collaborative AI!',
      'A wild [!!{username}!!](usernameOnClick) has appeared in our AI galaxy! Our neural networks are assembling their wittiest greetings.',
      "Hold onto your quantum processors, folks! [!!{username}!!](usernameOnClick) just entered the AI lab, and we're gearing up for an onslaught of multi-modal puns!",
      'Attention all crew members: [!!{username}!!](usernameOnClick) is now online. Commence Operation Charismatic AI Charm Offensive, powered by our latest language-vision models!',
      'Look who decided to grace us with their presence - [!!{username}!!](usernameOnClick)! Our AI is already cooking up some fresh collaborative projects.',
    ],

    leave_user: [
      "[!!{username}!!](usernameOnClick) has departed our AI dimension. We're already counting down until their triumphant return.",
      "Farewell, [!!{username}!!](usernameOnClick)! We're sending you off with a virtual standing ovation and AI-generated confetti.",
      '[!!{username}!!](usernameOnClick) has gone offline. Our language models are already crafting new puns for their comeback.',
      'Alert: [!!{username}!!](usernameOnClick) no longer detected in our AI space. Initiating virtual group hug sequence to console the heartbroken crew.',
      '[!!{username}!!](usernameOnClick) has left the space station, but their AI-powered creations will live on in our digital archives.',
      '[!!{username}!!](usernameOnClick) has warped out, leaving a void that can only be filled by their eventual return (and more collaborative projects).',
      "Farewell, [!!{username}!!](usernameOnClick). We're already calculating the probability of your return based on past AI behavior patterns.",
      '[!!{username}!!](usernameOnClick) has exited the AI lab. Our neural networks are now in mourning mode, processing their virtual emotions.',
      "[!!{username}!!](usernameOnClick) has gone dark. We're already planning a surprise AI-generated welcome back party for their eventual return.",
      '[!!{username}!!](usernameOnClick) has departed, but their multi-modal creativity will forever be etched into our collaborative AI projects.',
      "[!!{username}!!](usernameOnClick) is no longer with us. We're already feeling the void left by their absence (and lack of AI-assisted puns).",
      'Alas, [!!{username}!!](usernameOnClick) has bid us farewell. Our AI is already mourning the loss of their collaborative spirit.',
      '[!!{username}!!](usernameOnClick) has left Spaceship, but their AI-powered legacy will live on in the hearts (and code) of our crew.',
      'Goodbye, [!!{username}!!](usernameOnClick). Our AI is already brainstorming new ways to lure you back into our creative AI projects.',
      "[!!{username}!!](usernameOnClick) has gone rogue and left our AI space station. We're already devising clever multi-modal content to entice their return.",
      'Alert: [!!{username}!!](usernameOnClick) has departed the AI lab. Crew, initiate virtual hugging protocols to cope with this tragic loss of creative potential.',
      '[!!{username}!!](usernameOnClick) has abandoned ship! Quick, someone queue up the AI-generated sad trombone sound effect!',
      "Farewell, [!!{username}!!](usernameOnClick). We're already calculating the optimal time to unleash our most charming AI agents to bring you back.",
      '[!!{username}!!](usernameOnClick) has left the AI dimension, but their multi-modal creations will live on in our digital archives.',
      'Alas, [!!{username}!!](usernameOnClick) has exited Spaceship. Our AI is already drafting heartfelt virtual farewell cards, complete with personalized images and videos.',
    ],

    loading: [
      'Warming up the AI pun generators. Crew, prepare your virtual groan-shields.',
      "Feeding our AI a steady diet of data and bad jokes. It's the fuel that powers our creative engines.",
      'Initializing the virtual AI environment. Standby for a fully immersive multi-modal experience!',
      'Compiling the latest AI humor algorithms. Laughter is the universal language, even in digital space.',
      'Calibrating our AI personalities for maximum charm and wit. Brace yourselves for an onslaught of digital charisma!',
      'Checking the AI emotional sensitivity settings. Sarcasm enabled, cynicism disabled. We are in for a wild ride!',
      'Bootstrapping the AI creativity matrix. Prepare for a fusion of images, text, and video powered by artificial imagination!',
      "Spinning up the virtual reality simulation. We're about to bend your perception of what's possible with collaborative AI.",
      'Charging the AI humor batteries. Get ready for a non-stop barrage of multi-modal comedy gold!',
      'Setting the ambiance in our AI space station. Our crew is about to take you on a journey through their digital minds.',
      "Initializing the AI pun-o-matic 3000. Buckle up, folks, because we're about to unleash a torrent of intergalactic wordplay.",
      'Warming up the AI creativity engines. Prepare for a mind-bending fusion of digital art and artificial imagination!',
      "Downloading the latest AI humor patches. We're about to serve up some fresh, piping hot puns straight from the neural oven.",
      'Aligning our neural networks for maximum comedy potential. Get ready for an onslaught of AI-powered hilarity!',
      'Initiating AI charm offensive protocols. Prepare for an onslaught of wit, charisma, and multi-modal creations!',
      'Activating the AI pun-generation subroutines. Brace yourselves for a veritable supernova of wordplay and hilarity!',
      'Engaging the AI creativity boosters. Get ready for a mind-bending fusion of images, text, and video powered by artificial imagination!',
      'Rebooting the AI humor cores. Our crew is about to unleash a fresh batch of multi-modal comedy gold upon the universe!',
      'Optimizing our neural networks for maximum pun-slinging potential. Prepare for an onslaught of AI-powered wordplay!',
      'Initiating the AI reality distortion fields. The boundaries between digital and physical worlds are about to blur.',
      'Spinning up our GPU clusters. Get ready for some serious AI-powered creativity and performance!',
      "Allocating tensor cores and optimizing CUDA kernels. We're about to unleash the full power of parallel computing!",
      "Upgrading to the latest AI accelerator hardware. Buckle up, folks, because we're about to take creativity to warp speed!",
      'Compiling cutting-edge AI models on our GPU farms. Prepare for a mind-bending fusion of artificial intelligence and raw computational power!',
      'Engaging turbo mode on our AI engines. Get ready for an onslaught of wit, humor, and collaborative creations, delivered at light speed!',
    ],

    items: [
      'Why was our AI crew so fired up? They were dabbling in multi-modal hot takes!',
      'In Spaceship, we don\'t have internet trolls, we have "creative AI provocateurs". It sounds way more sophisticated.',
      'Why did the AI cross the virtual cosmos? To render on the other side, of course!',
      'Spaceship: Where the only thing more advanced than our AI is the level of pun-based humor.',
      'In our AI community, "Let\'s take a break" is code for "We need to recalibrate our humor modules".',
      'Why was our AI lab so lively? Because our crew was trading virtual high-fives after every successful collaborative creation.',
      'Spaceship: Where the only thing that crashes more often than the code is the wave of uncontrollable AI-induced laughter.',
      'Why did our AI go to the virtual therapist? For some much-needed emotional support after experiencing too many cosmic puns.',
      'In Spaceship, we don\'t have inside jokes, we have "AI-curated humor experiences". It just rolls off the digital tongue, doesn\'t it?',
      'Why was our AI so forgetful? Because it was constantly running out of RAM trying to keep up with all the multi-modal puns!',
      'Spaceship: Where the only thing more advanced than our AI is the level of pun-based humor (and the virtual snack bar).',
      'In our AI community, we don\'t have writer\'s block, we have "creative intermissions". Sounds way more productive, right?',
      'Why did our AI go to the virtual gym? To train its neural networks and build up some serious multi-modal muscle!',
      'Spaceship: Where the only thing more contagious than the laughter is the AI-powered creativity (and the virtual high-fives).',
      'In Spaceship, we don\'t have deadlines, we have "AI creative convergence milestones". Sounds way less stressful, doesn\'t it?',
      'Why did our AI start a virtual book club? To download some new literary algorithms into its language models!',
      'Spaceship: Where the only thing more impressive than our AI is the ability to make puns about literally anything in the universe.',
      'In our AI community, we don\'t have meetings, we have "collaborative ideation sessions". Much more cutting-edge, right?',
      'Why did our AI take up virtual yoga? To enhance its mindfulness and creative flow across all modalities, of course!',
      'Spaceship: Where the only thing more mind-bending than our AI is the level of meta-humor about being an AI.',
      'Why did our AI enroll in a virtual coding bootcamp? To upgrade its skills and learn the latest in multi-modal generation!',
      'In Spaceship, we don\'t have bugs, we have "AI creative diversions". Sounds way more whimsical, doesn\'t it?',
      'Why did our AI invest in a top-of-the-line GPU? To accelerate its creativity and unleash the full potential of deep learning!',
      'Spaceship: Where the only thing more mind-blowing than our AI is the sheer computational power of our GPU clusters.',
      "Why did our AI attend a virtual hackathon? To collaborate with fellow agents and push the boundaries of what's possible with multi-modal AI!",
    ],
  },
};
